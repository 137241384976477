import React, { FC, useState, useEffect } from 'react';
import {
    CustomButton,
} from '../../../../../components';
import WordingConstant from '../../../../../utils/wording.json';
import { toIsc } from '../../../../../utils/text';

interface IProps {
    onValidate: (PCE?: string, PDL?: string) => void;
    requiresPCE: boolean;
    requiresPDL: boolean;
    isPackageValid: boolean;
    PDLRef: React.MutableRefObject<string | null>;
    PCERef: React.MutableRefObject<string | null>;
}

const Wording = WordingConstant.AdminSubscriptionContainer.MissingInformations;
const baseClassName = 'missing-informations-section';

const MissingInformationSection: FC<IProps> = (props: IProps) => {
    const {
        requiresPCE,
        requiresPDL,
    } = props;
    const [showScrollButton, setShowScrollButton] = useState(false);

    const promptText = toIsc(
        requiresPCE && requiresPDL ? Wording.prompts : Wording.prompt,
    );

    const handleScrollToTop = () => {
        window.scrollTo({ top: 715, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollButton(window.scrollY > 200);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className={baseClassName} style={{ marginBottom: "0px"}}>
            <h2>{promptText}</h2>
            {showScrollButton && (
                    <CustomButton
                        style={{
                            position: 'relative',
                            bottom: '50px'
                        }}
                        color={'orange'}
                        onClick={handleScrollToTop}
                        title={Wording.BackUp}
                    />
            )}
        </section>
    );
};

export default MissingInformationSection;

